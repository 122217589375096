import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get, post } from '../../../utils/apiManager'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormLabel,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import ReactToPrint from 'react-to-print'
import { Button, Modal } from '@coreui/coreui'
import {
  FaRegEye,
  FaPrint,
  FaSearchengin,
  FaTruckMoving,
  FaRegAddressCard,
  FaShoppingBag,
  FaBarcode,
} from 'react-icons/fa'
import { ImageUrl } from 'src/config/config'
import Select from 'react-select'
import { cibElectron } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CDatePicker } from '@coreui/react-pro'
import * as dayjs from 'dayjs'
import '@coreui/coreui-pro/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

function OrderList() {
  const navigate = useNavigate()
  let componentRef = useRef()
  let inputScannerRef = useRef()
  // const [visible, setVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modalData, setModalData] = useState()
  const [logo, setLogo] = useState()
  const [placedOrderList, setPlacedOrderList] = useState()

  const [pickupDrivers, setPickupDrivers] = useState(null)
  const [selectedPickupDrivers, setSelectedPickupDrivers] = useState(null)
  const pickupDriverOptions = []

  const [deliverDrivers, setDeliverDrivers] = useState(null)
  const [selectedDeliverDrivers, setSelectedDeliverDrivers] = useState(null)
  const deliverDriverOptions = []

  const [searchItem, setSearchItem] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [count, setCount] = useState()
  const [deletedArray, setDeletedArray] = useState([])
  const [initialValue, setInitialValue] = useState(false)
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [qrValue, setQrValue] = useState("")

  const onClickOrderReply = (item) => {
    setVisible(true)
  }

  const getData = () => {
    get(
      `/admin/placed-order/deliverable-orders?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}&dateFilter=${selectedDate}`,
    ).then((res) => {
      if (res) {

        handleResponse(res)
      }
    }
    )
  }




  const handleResponse = (res) => {
    setPlacedOrderList(res?.data[0]?.data)
    setTotalPages(res?.data[0]?.metadata[0]?.page)
    setCount(res?.data[0]?.metadata[0]?.total)
    setInitialValue(!initialValue)
  }

  useEffect(() => {
    getData()
    getLogo()
    getDriver()
  }, [pageData, selectedDate])

  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    })
  }

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    })
  }

  const handleExaminItem = (item) => {
    if (item?.packageUser?.length > 0) {
      navigate(
        `/admin/placed-order/examin-package-items/${btoa(item?._id)}/${btoa(item?.orderId)}/${btoa(
          item?.user[0]?._id,
        )}`,
        { state: item },
      )
    } else {
      navigate(
        `/admin/placed-order/examin/${btoa(item?._id)}/${btoa(item?.orderId)}/${btoa(
          item?.user[0]?._id,
        )}`,
        { state: item },
      )
    }
  }

  const onClickUserView = (data) => {
    navigate(`/admin/offer/view/${btoa(data?._id)}/${btoa(data?.service_type)}`, { state: data })
  }
  const onClickUserEdit = (data) => {
    navigate(`/admin/offer/edit/${btoa(data?._id)}/${btoa(data?.service_type)}`, { state: data })
  }
  const getLogo = () => {
    get(`/admin/global/global-setting`).then((response) => {
      if (response?.status == true) {
        setLogo(`${ImageUrl}${response.data.logoImage}`)
      }
    })
  }
  const getDriver = () => {
    get(`/admin/placed-order/get-driver`).then((response) => {
      setPickupDrivers(response?.data)
      setDeliverDrivers(response?.data)
    })
  }
  if (pickupDrivers !== null) {
    pickupDrivers?.map((item, key) => {
      pickupDriverOptions?.push({ label: item?.username, value: item?._id })
    })
  }
  if (deliverDrivers !== null) {
    deliverDrivers?.map((item, key) => {
      deliverDriverOptions?.push({ label: item?.username, value: item?._id })
    })
  }
  const ChangeDriver = async (data) => {
    setVisible(true)
    setModalData(data)
  }
  const onClickChangeDriver = async (val) => {
    val.preventDefault()
    const changeDriver = {
      orderId: modalData?.orderId,
      pickup_driver: val?.target?.PickupDriver != undefined ? val?.target?.PickupDriver.value : '',
      dropOff_driver:
        val?.target?.DeliverDriver != undefined ? val?.target?.DeliverDriver.value : '',
    }
    post(`admin/placed-order/change-driver`, changeDriver).then((data) =>
      handleDriverResponse(data),
    )
  }
  const handleDriverResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setVisible(false)
    }
  }

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/placed-order/deleted-order`, deletedArray).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      //   get(
      //     `/admin/placed-order/order-placed-list?page=${pageData?.page || 1}&perPage=${
      //       pageData?.perPage || 10
      //     }&searchItem=${pageData?.searchItem || ''}&from_date=${searchDate ? searchDate : ''}`,
      //   ).then((res) => handleResponse(res))
      getData()
    }
  }


  const handleKeyPress = () => {

    get(`/admin/offline-order/get-order-id/${qrValue}`).then((data) => {
      if (data.status) {
        if (data.order) {
          navigate(`/admin/checkout-orders/bags`, { state: { orderId: qrValue } })
        }
      } else {
        toast.dismiss();
        toast.error(data.message)
      }
    })

  }

  useEffect(() => {
    if (qrValue.length != "" && qrValue?.length > 22) {
      handleKeyPress()
    }

  }, [qrValue])

  useEffect(() => {
    inputScannerRef.current.focus()
  }, [])

  useEffect(() => {

    onSearchCLick()
  }, [searchItem])



  return (
    <>
      {/* <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} /> */}
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4" onClick={(e) => { inputScannerRef.current.focus(), console.log("chala hu>>>", e.target.value); }}>
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Deliverable Orders</CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="4">

                      <input type="text" className=''
                        ref={inputScannerRef}
                        style={{ width: "0px", height: "0px", border: "none" }}
                        onChange={(e) => setQrValue(e.target.value)}
                      />
                    </CCol>
                    <CCol md="4">
                      <CDatePicker
                        locale="en-US"
                        date={selectedDate}
                        onDateChange={(e) => {
                          setSelectedDate(e == null ? dayjs().format('YYYY-MM-DD') : dayjs(e).format('YYYY-MM-DD'))
                        }}
                      />
                    </CCol>
                    <CCol md="4">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        // onClick={onSearchCLick}
                        onChange={(e) => {
                          setSearchItem(e?.target?.value)
                          // onSearchCLick()

                        }}
                      />
                    </CCol>
                    {/* <CCol md="4">
                      <Link
                        to="/admin/checkout-orders/scan"
                        className="btn btn-success"
                        style={{
                          width: '100%',
                          background: '#d3dc40',
                          border: '#d3dc40',
                          padding: '10px 0',
                        }}
                      >
                        Scan QR
                      </Link>
                    </CCol> */}
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Order ID</CTableHeaderCell>
                        <CTableHeaderCell>Order Status</CTableHeaderCell>
                        <CTableHeaderCell>Order Type</CTableHeaderCell>
                        <CTableHeaderCell>User Type</CTableHeaderCell>
                        <CTableHeaderCell>User Name</CTableHeaderCell>
                        <CTableHeaderCell>Mobile Number</CTableHeaderCell>
                        <CTableHeaderCell>Area</CTableHeaderCell>
                        <CTableHeaderCell>Transaction</CTableHeaderCell>
                        <CTableHeaderCell>Date</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {placedOrderList?.length > 0 && placedOrderList?.map((item, index) => {
                        return (
                          <>
                            {item?.bags?.length > 0 && <CTableRow key={index}>


                              <CTableDataCell onClick={() => handleCheck(item._id)}>
                                {pageData?.perPage * (pageData?.page - 1) + index + 1}
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    style={{ marginLeft: '2px', marginTop: '-20px' }}
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={deletedArray?.includes(item._id)}
                                  //   onChange={() => handleCheck(item._id)}
                                  />
                                </div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <Link
                                  to={{
                                    pathname: `/admin/placed-order/item-detail/${btoa(item._id,)}/${btoa(item.orderId)}`,
                                    state: item,
                                  }}
                                  style={{ textDecoration: 'none', color: '#80861C' }}
                                >
                                  #{item?.orderId}
                                </Link>
                              </CTableDataCell>
                              <CTableDataCell>
                                {!item.paid && item?.areAllBagsCompleted ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#dc3545', border: '#dc3545' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Wait for</strong> <br />{" "}
                                      <strong>payment???</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'order_place' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#d3dc40', border: '#d3dc40' }}
                                    outline={item?.orderStatus}>
                                    <span>
                                      <strong>Order Place</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'cancel(request)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Customer Request </strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'cancel(customer)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#757575', border: '#757575' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Cancel By Customer </strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'picked_up' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#FBC02D', border: '#FBC02D' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Picked Up</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'inprocess(Examine clothes)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#757575', border: '#757575' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>
                                        In Process <FaSearchengin />
                                      </strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'inprocess(service process)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>
                                        In Process{' '}
                                        <CIcon
                                          icon={cibElectron}
                                          customClassName="nav-icon"
                                          style={{ height: '20px' }}
                                        />
                                      </strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'inprocess(payment success)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: 'rgb(165 138 138)', border: '#BDBDBD' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Payment Process </strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'cancel' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#FF0000', border: '#FF0000' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Cancel</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'hold' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#2196F3', border: '#2196F3' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Hold</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'delivery' ? (
                                  <CButton
                                    size="sm"
                                    style={
                                      !item.paid
                                        ? { backgroundColor: '#FF0000', border: '#FF0000' }
                                        : { backgroundColor: '#689F38', border: '#689F38' }
                                    }
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Ready to Deliver</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'out_for_delivery' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#689F38', border: '#689F38' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Out for deliver</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'deliverd' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#2E7D32', border: '#2E7D32' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Deliverd</strong>
                                    </span>
                                  </CButton>
                                ) : (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#757575', border: '#757575' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>
                                        In Process{' '}
                                        <FaSearchengin />
                                      </strong>
                                    </span>
                                  </CButton>
                                )}

                                {/* {} */}
                              </CTableDataCell>

                              <CTableDataCell>
                                <span>{item.orderType}</span>
                              </CTableDataCell>
                              <CTableDataCell
                                style={{
                                  color: item?.packageUser?.length > 0 ? '#A79B46' : '#2196F3',
                                  fontWeight: item?.packageUser?.length > 0 ? 500 : 500,
                                }}
                              >
                                {item?.packageUser?.length > 0 ? 'PACKAGE USER' : 'REGULER USER'}
                              </CTableDataCell>
                              <CTableDataCell>{item?.user?.username}</CTableDataCell>
                              <CTableDataCell>{item?.user?.mobile}</CTableDataCell>
                              <CTableDataCell>
                                {item?.areaDetail?.areaName_EN ? item?.areaDetail?.areaName_EN : '-'}
                              </CTableDataCell>
                              <CTableDataCell>{item?.paid ? 'Paid' : 'Unpaid'}</CTableDataCell>
                              <CTableDataCell>{new Date(item?.createdAt).toLocaleDateString()}</CTableDataCell>
                              <CTableDataCell>
                                <span
                                  className="mx-2"
                                  onClick={() => {
                                    console.log({ item })
                                    item?.orderStatus == 'cancel(request)' ||
                                      item?.orderStatus == 'cancel(customer)'
                                      ? null
                                      : navigate(`/admin/checkout-orders/bags`, { state: item })
                                  }}
                                  style={{
                                    fontSize: '25px',
                                  }}>
                                  <FaShoppingBag />
                                </span>
                              </CTableDataCell>
                            </CTableRow>}
                          </>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!placedOrderList?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {placedOrderList?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
          <CModalHeader onClose={() => setVisible(false)}>
            <CModalTitle>
              <img src={logo} /> Change the driver for Order No #{modalData?.orderId}
            </CModalTitle>
          </CModalHeader>
          <form onSubmit={onClickChangeDriver}>
            <CModalBody>
              <CRow>
                {modalData?.orderStatus == 'order_place' ? (
                  <>
                    <CFormLabel>Pickup Driver</CFormLabel>
                    <Select
                      onChange={(val) => {
                        setSelectedPickupDrivers(val)
                      }}
                      options={pickupDriverOptions}
                      value={selectedPickupDrivers}
                      placeholder={'Select Pickup Driver'}
                      name={'PickupDriver'}
                    />
                  </>
                ) : (
                  ''
                )}
                {modalData?.orderStatus == 'delivery' ? (
                  <>
                    <CFormLabel>Deliver Driver</CFormLabel>
                    <Select
                      onChange={(val) => {
                        setSelectedDeliverDrivers(val)
                      }}
                      options={deliverDriverOptions}
                      value={selectedDeliverDrivers}
                      placeholder={'Select Deliver Driver'}
                      name={'DeliverDriver'}
                    />
                  </>
                ) : (
                  ''
                )}
              </CRow>
            </CModalBody>
            <CModalFooter>
              <CButton color="success" type="submit">
                Submit
              </CButton>
            </CModalFooter>
          </form>
        </CModal>
      </CContainer>
    </>
  )
}

export default OrderList
