import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get, post } from '../../utils/apiManager'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormLabel,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import { DefaultInputWithIcon } from 'src/components/common/input'
import Pagination from 'react-js-pagination'
import ReactToPrint from 'react-to-print'
import { Button, Modal } from '@coreui/coreui'
import { FaRegEye, FaPrint, FaSearchengin, FaTruckMoving, FaRegAddressCard } from 'react-icons/fa'
import { ImageUrl } from 'src/config/config'
import Select from 'react-select'
import { cibElectron } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import Lottie from 'lottie-react'
import groovyWalkAnimation from './groovyWalk.json'
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import moment from 'moment'
import { ChangeOrderStatus } from 'src/constants/statusConstants'

function allOrders() {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [allOrders, setAllOrders] = useState(true)
  const [modalData, setModalData] = useState()
  const [logo, setLogo] = useState()
  const [placedOrderList, setPlacedOrderList] = useState()

  const [pickupDrivers, setPickupDrivers] = useState(null)
  const [selectedPickupDrivers, setSelectedPickupDrivers] = useState(null)
  const [deletedArray, setDeletedArray] = useState([])
  const pickupDriverOptions = []

  const [deliverDrivers, setDeliverDrivers] = useState(null)
  const [selectedDeliverDrivers, setSelectedDeliverDrivers] = useState(null)
  const deliverDriverOptions = []

  const [searchItem, setSearchItem] = useState('')
  const [searchDate, setSearchDate] = useState('')
  const [filterOrderStatus, setFilterOrderStatus] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState()
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [selectedOptions, setSelectedOption] = useState([])

  const OrderStatusOptions = [
    { value: ChangeOrderStatus?.obj?.OrderPlace, label: 'Order Placed' },
    // { value: ChangeOrderStatus?.obj?.CancelRequest, label: "Cancel Request" },
    { value: ChangeOrderStatus?.obj?.pickedUp, label: 'Picked Up' },
    { value: ChangeOrderStatus?.obj?.InprocessClothesExamine, label: 'Clothes examined' },
    // { value: ChangeOrderStatus?.obj?.InprocessService, label: "Service Process" },
    { value: ChangeOrderStatus?.obj?.InprocessPayment, label: 'Waiting for payment' },
    // { value: ChangeOrderStatus?.obj?.delivery, label: "Delivery" },
    // { value: ChangeOrderStatus?.obj?.deliverd, label: "waiting for payment" },
    { value: ChangeOrderStatus?.obj?.deliverd, label: 'Deliverd' },
    { value: ChangeOrderStatus?.obj?.hold, label: 'Pending' },
    { value: ChangeOrderStatus?.obj?.CancelCustomer, label: 'Cancel customer' },
    { value: ChangeOrderStatus?.obj?.CancelDriver, label: 'Cancel by driver' },
    { value: ChangeOrderStatus?.obj?.CancelAdmin, label: 'Cancel by admin' },
    { value: ChangeOrderStatus?.obj?.OutForDelivery, label: 'Out For Delivery' },
  ]

  const onClickOrderReply = (item) => {
    setVisible(true)
  }

  const getData = () => {
    get(
      `/admin/placed-order/order-placed-list?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}&from_date=${searchDate ? searchDate : ''
      }&filter=${filterOrderStatus}`,
    ).then((res) => handleResponse(res))
  }

  const handleResponse = (res) => {
    setTimeout(() => {
      setAllOrders(false)
    }, 1500)
    setPlacedOrderList(res?.data[0]?.data)
    setTotalPages(res?.data[0]?.metadata[0]?.page)
    setCount(res?.data[0]?.metadata[0]?.total)
  }

  useEffect(() => {
    getData()
    getLogo()
    getDriver()
  }, [pageData, searchDate, filterOrderStatus])

  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    })
  }

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    })
  }

  const handleExaminItem = (item) => {
    if (item?.packageUser?.length > 0) {
      navigate(
        `/admin/placed-order/examin-package-items/${btoa(item?._id)}/${btoa(item?.orderId)}/${btoa(
          item?.user[0]?._id,
        )}`,
        { state: item },
      )
    } else {
      navigate(
        `/admin/placed-order/examin/${btoa(item?._id)}/${btoa(item?.orderId)}/${btoa(
          item?.user[0]?._id,
        )}`,
        { state: item },
      )
    }
  }

  const onClickUserView = (data) => {
    navigate(`/admin/offer/view/${btoa(data?._id)}/${btoa(data?.service_type)}`, { state: data })
  }
  const onClickUserEdit = (data) => {
    navigate(`/admin/offer/edit/${btoa(data?._id)}/${btoa(data?.service_type)}`, { state: data })
  }
  const getLogo = () => {
    get(`/admin/global/global-setting`).then((response) => {
      if (response?.status == true) {
        setLogo(`${ImageUrl}${response.data.logoImage}`)
      }
    })
  }
  const getDriver = () => {
    get(`/admin/placed-order/get-driver`).then((response) => {
      setPickupDrivers(response?.data)
      setDeliverDrivers(response?.data)
    })
  }
  if (pickupDrivers !== null) {
    pickupDrivers?.map((item, key) => {
      pickupDriverOptions?.push({ label: item?.username, value: item?._id })
    })
  }
  if (deliverDrivers !== null) {
    deliverDrivers?.map((item, key) => {
      deliverDriverOptions?.push({ label: item?.username, value: item?._id })
    })
  }
  const ChangeDriver = async (data) => {
    if (data?.orderStatus == 'order_place') {
      let pickupDriver = pickupDrivers.find((v) => v._id.toString() == data?.pickup_driver.toString())
      setSelectedPickupDrivers({ label: pickupDriver?.username, value: pickupDriver?._id })
    } else {
      let deliveryDriver = deliverDrivers.find((v) => v._id.toString() == data?.dropOff_driver.toString())
      setSelectedDeliverDrivers({ label: deliveryDriver?.username, value: deliveryDriver?._id })
    }

    setVisible(true)
    setModalData(data)
  }
  const onClickChangeDriver = async (val) => {
    val.preventDefault()
    const changeDriver = {
      orderId: modalData?.orderId,
      pickup_driver: val?.target?.PickupDriver != undefined ? val?.target?.PickupDriver.value : '',
      dropOff_driver:
        val?.target?.DeliverDriver != undefined ? val?.target?.DeliverDriver.value : '',
    }
    post(`admin/placed-order/change-driver`, changeDriver).then((data) =>
      handleDriverResponse(data),
    )
  }
  const handleDriverResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setVisible(false)
    }
  }

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/placed-order/deleted-order`, deletedArray).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      get(
        `/admin/placed-order/order-placed-list?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
        }&searchItem=${pageData?.searchItem || ''}&from_date=${searchDate ? searchDate : ''
        }&filter={}`,
      ).then((res) => handleResponse(res))
    }
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">All Orders</CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>

                  <CRow>
                    {/* Option  */}
                    <CCol md="4" styles={{ width: '10px' }}>
                      <Select
                        isMulti
                        options={OrderStatusOptions}
                        onChange={(e) => {
                          console.log('selectoptionsle', e)
                          let filter = []
                          e.filter((item) => filter.push(item.value))
                          setFilterOrderStatus(filter.join(','))
                        }}
                        onMenuClose={() => {
                          if (filterOrderStatus?.length == 0) {
                            setPageData({ page: 1, perPage: 10, searchItem: '' })
                          }
                        }}
                      />
                    </CCol>
                    <CCol md="4">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          maxDate={new Date()}
                          value={searchDate ? searchDate : null}
                          onChange={(e) => {
                            if (e?.$d && e?.$d != 'Invalid Date') {
                              let date = new Date(e?.$d)?.toISOString()
                              setSearchDate(date)
                            } else {
                              setSearchDate('')
                            }

                            // if(new Date(e)){
                            //   setSearchDate(e?.$d)
                            // }
                          }}
                          renderInput={(params) => (
                            <TextField style={{ marginTop: '5px' }} {...params} />
                          )}
                        />
                      </LocalizationProvider>
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker label="Basic date picker" />
                        </DemoContainer>
                      </LocalizationProvider> */}
                    </CCol>
                    <CCol md="4">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setSearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                  </CRow>
                </CHeader>
                {allOrders !== true ? (
                  <CCardBody>
                    <CTable bordered hover responsive>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell>S. No</CTableHeaderCell>
                          <CTableHeaderCell>Order ID</CTableHeaderCell>
                          <CTableHeaderCell>Order Status</CTableHeaderCell>
                          <CTableHeaderCell>Order Type</CTableHeaderCell>
                          <CTableHeaderCell>User Type</CTableHeaderCell>
                          <CTableHeaderCell>User Name</CTableHeaderCell>
                          <CTableHeaderCell>Mobile Number</CTableHeaderCell>
                          <CTableHeaderCell>Driver Area</CTableHeaderCell>
                          <CTableHeaderCell>Date</CTableHeaderCell>
                          <CTableHeaderCell>Action</CTableHeaderCell>
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {placedOrderList?.map((item, index) => {
                          // console.log(item?.packageUser?.length, "placeorder List items")
                          return (
                            <CTableRow key={index}>
                              <CTableDataCell
                                onClick={() => {
                                  handleCheck(item._id)
                                }}
                              >
                                {pageData?.perPage * (pageData?.page - 1) + index + 1}
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    style={{ marginLeft: '2px', marginTop: '-20px' }}
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    checked={deletedArray?.includes(item._id)}
                                  // onChange={() => handleCheck(item._id)}
                                  />
                                </div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <Link
                                  to={{
                                    pathname: `/admin/placed-order/item-detail/${btoa(
                                      item._id,
                                    )}/${btoa(item.orderId)}`,
                                    state: item,
                                  }}
                                  style={{ textDecoration: 'none', color: '#80861C' }}>
                                  #{item?.orderId}
                                </Link>
                              </CTableDataCell>
                              <CTableDataCell>
                                {item?.orderStatus == 'order_place' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#d3dc40', border: '#d3dc40' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Order Place</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'cancel(request)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Customer Request </strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'cancel(customer)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#757575', border: '#757575' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Cancel By Customer </strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'picked_up' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#FBC02D', border: '#FBC02D' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Picked Up</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'inprocess(Examine clothes)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#757575', border: '#757575' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>
                                        In Process <FaSearchengin />
                                      </strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'inprocess(service process)' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#BDBDBD', border: '#BDBDBD' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>
                                        In Process{' '}
                                        <CIcon
                                          icon={cibElectron}
                                          customClassName="nav-icon"
                                          style={{ height: '20px' }}
                                        />
                                      </strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'inprocess(payment success)' ? (
                                  <CButton
                                    size="sm"
                                    style={{
                                      backgroundColor: 'rgb(165 138 138)',
                                      border: '#BDBDBD',
                                    }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Payment Process </strong>
                                    </span>
                                  </CButton>
                                ) : (item?.orderStatus == ('cancel(driver)' || 'cancel(admin)')) ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#FF0000', border: '#FF0000' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Cancel</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'hold' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#2196F3', border: '#2196F3' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Hold</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'delivery' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#689F38', border: '#689F38' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      {/* <strong>Ready to Deliver</strong> */}
                                      <strong>Out for delivery</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'out_for_delivery' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#689F38', border: '#689F38' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Out for deliver</strong>
                                    </span>
                                  </CButton>
                                ) : item?.orderStatus == 'deliverd' ? (
                                  <CButton
                                    size="sm"
                                    style={{ backgroundColor: '#2E7D32', border: '#2E7D32' }}
                                    outline={item?.orderStatus}
                                  >
                                    <span>
                                      <strong>Deliverd</strong>
                                    </span>
                                  </CButton>
                                ) : (
                                  'In Process'
                                )}

                                {/* {} */}
                              </CTableDataCell>
                              <CTableDataCell>
                                <span>{item.orderType}</span>
                              </CTableDataCell>
                              <CTableDataCell
                                style={{
                                  color: item?.packageUser?.length > 0 ? '#A79B46' : '#2196F3',
                                  fontWeight: item?.packageUser?.length > 0 ? 500 : 500,
                                }}
                              >
                                {item?.packageUser?.length > 0 ? 'PACKAGE USER' : 'REGULER USER'}
                              </CTableDataCell>
                              <CTableDataCell>
                                {item?.user?.map((i, k) => {
                                  return <span>{i?.username}</span>
                                })}
                              </CTableDataCell>
                              <CTableDataCell>
                                {item?.user?.map((i, k) => {
                                  return <span>{i?.mobile}</span>
                                })}
                              </CTableDataCell>
                              {/* <CTableDataCell ><img src={item?.orderQR} style={{ width: '40px' }} /></CTableDataCell> */}
                              <CTableDataCell>
                                {item?.areaDetail?.areaName_EN
                                  ? item?.areaDetail?.areaName_EN
                                  : '-'}
                              </CTableDataCell>
                              <CTableDataCell>
                                {new Date(item?.createdAt).toLocaleDateString()}
                              </CTableDataCell>
                              <CTableDataCell>
                                {/* {console.log(item.orderStatus, "order ka status")} */}
                                <span
                                  className="mx-2"
                                  onClick={() =>
                                    item?.orderStatus == 'cancel(request)' ||
                                      item?.orderStatus == 'cancel(customer)'
                                      ? null
                                      : navigate(
                                        `/admin/placed-order/print/${btoa(item?._id)}/${btoa(
                                          item?.orderId,
                                        )}`,
                                        { state: item },
                                      )
                                  }
                                  style={{
                                    cursor:
                                      item?.orderStatus == 'cancel(request)' ||
                                        item?.orderStatus == 'cancel(customer)'
                                        ? 'not-allowed'
                                        : 'pointer',
                                    fontSize:
                                      item?.orderStatus == 'cancel(request)' ||
                                        item?.orderStatus == 'cancel(customer)'
                                        ? '20px'
                                        : '20px',
                                    color:
                                      item?.orderStatus == 'cancel(request)' ||
                                        item?.orderStatus == 'cancel(customer)'
                                        ? '#9E9E9E'
                                        : '#d3dc40',
                                    // fontSize: '20px', color: '#000', cursor: 'pointer', fontSize: '20px'
                                  }}
                                >
                                  <FaPrint />
                                </span>
                                {/* EXAMINE PACKET  */}
                                {/* <span
                                  className="mx-2"
                                  onClick={() =>
                                    item?.orderStatus == 'order_place' ||
                                      item?.orderStatus == 'cancel(request)' ||
                                      item?.orderStatus == 'cancel(customer)' ||
                                      item?.orderStatus == 'inprocess(service process)' ||
                                      item?.orderStatus == 'inprocess(payment success)' ||
                                      item?.orderStatus == 'hold' ||
                                      item?.orderStatus == 'delivery' ||
                                      item?.orderStatus == 'out_for_delivery' ||
                                      item?.orderStatus == 'deliverd'
                                      ? null
                                      : handleExaminItem(item)
                                  }
                                  style={{
                                    color:
                                      item?.orderStatus == 'cancel(request)' ||
                                        item?.orderStatus == 'order_place' ||
                                        item?.orderStatus == 'cancel(customer)' ||
                                        item?.orderStatus == 'inprocess(service process)' ||
                                        item?.orderStatus == 'inprocess(payment success)' ||
                                        item?.orderStatus == 'hold' ||
                                        item?.orderStatus == 'delivery' ||
                                        item?.orderStatus == 'out_for_delivery' ||
                                        item?.orderStatus == 'deliverd'
                                        ? '#9E9E9E'
                                        : '#d3dc40',
                                    fontSize:
                                      item?.orderStatus == 'cancel(request)' ||
                                        item?.orderStatus == 'order_place' ||
                                        item?.orderStatus == 'cancel(customer)' ||
                                        item?.orderStatus == 'inprocess(service process)' ||
                                        item?.orderStatus == 'inprocess(payment success)' ||
                                        item?.orderStatus == 'hold' ||
                                        item?.orderStatus == 'delivery' ||
                                        item?.orderStatus == 'out_for_delivery' ||
                                        item?.orderStatus == 'deliverd'
                                        ? '20px'
                                        : '20px',
                                    cursor:
                                      item?.orderStatus == 'cancel(request)' ||
                                        item?.orderStatus == 'order_place' ||
                                        item?.orderStatus == 'cancel(customer)' ||
                                        item?.orderStatus == 'inprocess(service process)' ||
                                        item?.orderStatus == 'inprocess(payment success)' ||
                                        item?.orderStatus == 'hold' ||
                                        item?.orderStatus == 'delivery' ||
                                        item?.orderStatus == 'out_for_delivery' ||
                                        item?.orderStatus == 'deliverd'
                                        ? 'not-allowed'
                                        : 'pointer',
                                  }}
                                >
                                  <FaSearchengin />
                                </span> */}

                                <span
                                  className="mx-2"
                                  onClick={() =>
                                    navigate(
                                      `/admin/placed-order/view/${btoa(item?._id)}/${btoa(
                                        item?.orderId,
                                      )}`,
                                      { state: item },
                                    )
                                  }
                                  style={{
                                    fontSize: '20px',
                                    color: '#d3dc40',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <FaRegEye />
                                </span>

                                <span
                                  className="mx-2"
                                  onClick={() =>
                                    item?.orderStatus == 'order_place' ||
                                      item?.orderStatus == 'inprocess(payment success)' ||
                                      item?.orderStatus == 'cancel(request)' ||
                                      item?.orderStatus == 'cancel(customer)' ||
                                      item?.orderStatus == 'picked_up'
                                      ? null
                                      : navigate(
                                        `/admin/placed-order/ready-to-deliver-order/${btoa(
                                          item?._id,
                                        )}/${btoa(item?.orderId)}`,
                                        { state: item },
                                      )
                                  }
                                  style={{
                                    cursor:
                                      (item?.orderStatus == item?.orderStatus) == 'order_place' ||
                                        'cancel(request)' ||
                                        item?.orderStatus == 'cancel(customer)' ||
                                        item?.orderStatus == 'picked_up'
                                        ? 'not-allowed'
                                        : 'pointer',
                                    fontSize:
                                      (item?.orderStatus == item?.orderStatus) == 'order_place' ||
                                        'cancel(request)' ||
                                        item?.orderStatus == 'cancel(customer)' ||
                                        item?.orderStatus == 'picked_up'
                                        ? '20px'
                                        : '20px',
                                    color:
                                      (item?.orderStatus == item?.orderStatus) == 'order_place' ||
                                        'cancel(request)' ||
                                        item?.orderStatus == 'cancel(customer)' ||
                                        item?.orderStatus == 'picked_up'
                                        ? '#9E9E9E'
                                        : '#d3dc40',
                                  }}
                                >
                                  <FaTruckMoving />
                                </span>
                                <span
                                  className="mx-2"
                                  onClick={() =>
                                    item?.orderStatus == 'cancel(request)' ||
                                      item?.orderStatus == 'cancel(customer)' ||
                                      item?.orderStatus == 'cancel(driver)' ||
                                      item?.orderStatus == 'cancel(admin)' ||
                                      item?.orderStatus == 'picked_up' ||
                                      item?.orderStatus == 'inprocess(Examine clothes)' ||
                                      item?.orderStatus == 'inprocess(service process)' ||
                                      item?.orderStatus == 'inprocess(payment success)' ||
                                      item?.orderStatus == 'hold' ||
                                      item?.orderStatus == 'out_for_delivery' ||
                                      item?.orderStatus == 'deliverd'
                                      ? null
                                      : ChangeDriver(item)
                                  }
                                  style={{
                                    color: item?.orderStatus == 'cancel(request)' ||
                                      item?.orderStatus == 'cancel(customer)' ||
                                      item?.orderStatus == 'cancel(driver)' ||
                                      item?.orderStatus == 'cancel(admin)' ||
                                      item?.orderStatus == 'picked_up' ||
                                      item?.orderStatus == 'inprocess(Examine clothes)' ||
                                      item?.orderStatus == 'inprocess(service process)' ||
                                      item?.orderStatus == 'inprocess(payment success)' ||
                                      item?.orderStatus == 'hold' ||
                                      item?.orderStatus == 'out_for_delivery' ||
                                      item?.orderStatus == 'deliverd' ? '#9E9E9E' : '#d3dc40',
                                    fontSize: item?.orderStatus == 'cancel(request)' ||
                                      item?.orderStatus == 'cancel(customer)' ||
                                      item?.orderStatus == 'picked_up' ||
                                      item?.orderStatus == 'inprocess(Examine clothes)' ||
                                      item?.orderStatus == 'inprocess(service process)' ||
                                      item?.orderStatus == 'inprocess(payment success)' ||
                                      item?.orderStatus == 'hold' ||
                                      item?.orderStatus == 'out_for_delivery' ||
                                      item?.orderStatus == 'deliverd'
                                      ? '20px'
                                      : '20px',
                                    cursor:
                                      item?.orderStatus == 'cancel(request)' ||
                                        item?.orderStatus == 'cancel(customer)' ||
                                        item?.orderStatus == 'picked_up' ||
                                        item?.orderStatus == 'inprocess(Examine clothes)' ||
                                        item?.orderStatus == 'inprocess(service process)' ||
                                        item?.orderStatus == 'inprocess(payment success)' ||
                                        item?.orderStatus == 'hold' ||
                                        item?.orderStatus == 'out_for_delivery' ||
                                        item?.orderStatus == 'deliverd'
                                        ? 'not-allowed'
                                        : 'pointer',
                                  }}
                                >
                                  <FaRegAddressCard />
                                </span>

                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    navigate(`/admin/order/chat/${item?.orderId}`)
                                  }}
                                >
                                  <i class="fas fa-comments" style={{ color: '#D3Dc40' }}></i>
                                </span>
                              </CTableDataCell>
                            </CTableRow>
                          )
                        })}
                      </CTableBody>
                    </CTable>
                    {!placedOrderList?.length && (
                      <>
                        <h1>No Data</h1>
                      </>
                    )}
                    {placedOrderList?.length ? (
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={pageData?.page}
                        itemsCountPerPage={pageData?.perPage}
                        totalItemsCount={count}
                        pageRangeDisplayed={5}
                        onChange={changePage}
                      />
                    ) : (
                      ''
                    )}
                  </CCardBody>
                ) : (
                  <div className="lodderClass" style={{ width: '50%', marginLeft: '20%' }}>
                    <Lottie animationData={groovyWalkAnimation} />
                  </div>
                )}
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <CModal alignment="center" visible={visible} onClose={() => setVisible(false)}>
          <CModalHeader onClose={() => setVisible(false)}>
            <CModalTitle>
              <img src={logo} /> Change the driver for Order No #{modalData?.orderId}
            </CModalTitle>
          </CModalHeader>
          <form onSubmit={onClickChangeDriver}>
            <CModalBody>
              <CRow>
                {modalData?.orderStatus == 'order_place' ? (
                  <>
                    <CFormLabel>Pickup Driver</CFormLabel>
                    <Select
                      onChange={(val) => {
                        setSelectedPickupDrivers(val)
                      }}
                      options={pickupDriverOptions}
                      value={selectedPickupDrivers}
                      placeholder={'Select Pickup Driver'}
                      name={'PickupDriver'}
                    />
                  </>
                ) : (
                  ''
                )}
                {modalData?.orderStatus == 'delivery' ? (
                  <>
                    <CFormLabel>Deliver Driver</CFormLabel>
                    <Select
                      onChange={(val) => {
                        setSelectedDeliverDrivers(val)
                      }}
                      options={deliverDriverOptions}
                      value={selectedDeliverDrivers}
                      placeholder={'Select Deliver Driver'}
                      name={'DeliverDriver'}
                    />
                  </>
                ) : (
                  ''
                )}
              </CRow>
            </CModalBody>
            <CModalFooter>
              <CButton color="success" type="submit">
                Submit
              </CButton>
            </CModalFooter>
          </form>
        </CModal>
      </CContainer>
    </>
  )
}

export default allOrders

const CloseSvg = ({ onClose }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-x"
      style={{ cursor: 'pointer' }}
      onClick={onClose}
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  )
}
